<template>
  <base-modal :modal.sync="modal" @changeModal="e => $emit('change-modal', e)">
    <div class="modal-info-entity">
      <div class="modal__topbar">
        <button class="button modal__close">
          <i class="icon icon-close"></i>
        </button>
      </div>

      <div
        class="modal__entity--cover"
        :style="`--cover: url('${$help.image(entityData.img)}')`"
        @click="handleCover"
      >
        <input type="file" hidden ref="coverUpload" @change="uploadCover" />
      </div>
      <div class="entities-avatar" @click="handleAvatar">
        <input type="file" hidden ref="avatarUpload" @change="uploadAvatar" />
        <img
          v-if="user.avatar"
          class="modal__entity--brand"
          :src="user.avatar"
          :alt="entityData.name"
        />
        <span v-else>
          {{ entityData.name[0] }}
        </span>
      </div>

      <div class="modal__content">
        <div class="row">
          <Input label="Título" class="col-12" v-model="entityData.name" />
          <Input label="Email" class="col-12" v-model="entityData.email" />
          <Input
            label="Telefone"
            class="col-12"
            v-model="entityData.contact_phone"
          />
          <Input label="Website" class="col-12" v-model="entityData.url" />
          <div class="col-12">
            <button type="button" class="button_outline" @click="submit()">
              Salvar
            </button>
          </div>
        </div>
      </div>
    </div>
  </base-modal>
</template>

<script>
import swal from 'sweetalert'
import axios from 'axios'
import { mapActions, mapGetters } from 'vuex'

const defaultForm = {
  id: null,
  name: '',
  contact_phone: '',
  url: '',
  email: ''
}

export default {
  props: {
    modal: Boolean,
    entityData: Object,
    entity: {}
  },

  data: () => ({
    form: { ...defaultForm },
    id: ''
  }),
  methods: {
    ...mapActions('user', ['getFromStorage']),

    async getHits () {
      this.loading = true
      const { id } = this.user
      let url = `entities/getEntity?id=${id}`
      const { startDate, endDate } = this

      if (startDate && endDate) {
        url += `&start_date=${startDate}&end_date=${endDate}`
      }

      const { data } = await this.$http.get(url)
      this.entityData = data.data
      this.loading = false
    },
    handleAvatar () {
      this.$refs.avatarUpload.click()
    },
    uploadAvatar (e) {
      if (this.loading) return
      this.form.file = {}
      const files = e.target.files
      if (!files.length) return
      const [file] = files

      const { type, size } = file
      const acceptedTypes = ['image/png', 'image/jpeg']

      if (!acceptedTypes.includes(type)) {
        return swal({
          title: 'Arquivo inválido!',
          text: 'Os arquivos aceitos são no formato PNG e JPG.',
          icon: 'error'
        })
      }

      if (size > 2097152) {
        return swal({
          title: 'Arquivo inválido!',
          text: 'O arquivo deve ser menor que 2MB.',
          icon: 'error'
        })
      }

      const render = new FileReader()

      render.onload = e => {
        const url = `entities/update/${this.user.id}`
        const data = { avatar: e.target.result, user_id: this.user.id }
        axios
          .request({ method: 'PUT', url, data })
          .then(response => {
            swal({
              title: 'Sucesso!',
              text: 'Foto de perfil atualizada com sucesos.',
              icon: 'success',
              timer: 1500
            })
            const localUser = JSON.parse(localStorage.getItem('user'))
            localUser.avatar = response.data.data.user.avatar_url
            localStorage.setItem('user', JSON.stringify(localUser))

            window.location.reload()
          })
          .catch(() => {
            swal({
              title: 'Ooops!',
              text: 'Erro ao atualizar foto de perfil',
              icon: 'error',
              button: 'Ok'
            })
          })
      }
      render.readAsDataURL(file)
    },
    handleCover () {
      this.$refs.coverUpload.click()
    },
    uploadCover (e) {
      if (this.loading) return
      this.form.file = {}
      const files = e.target.files
      if (!files.length) return
      const [file] = files

      const { type, size } = file
      const acceptedTypes = ['image/png', 'image/jpeg']

      if (!acceptedTypes.includes(type)) {
        return swal({
          title: 'Arquivo inválido!',
          text: 'Os arquivos aceitos são no formato PNG e JPG.',
          icon: 'error'
        })
      }

      if (size > 2097152) {
        return swal({
          title: 'Arquivo inválido!',
          text: 'O arquivo deve ser menor que 2MB.',
          icon: 'error'
        })
      }

      const render = new FileReader()

      render.onload = e => {
        const url = `entities/update/${this.user.id}`
        const data = { cover: e.target.result, user_id: this.user.id }
        axios
          .request({ method: 'PUT', url, data })
          .then(() => {
            swal({
              title: 'Sucesso!',
              text: 'Foto de capa atualizada com sucesos.',
              icon: 'success',
              timer: 1500
            })
            window.location.reload()
          })
          .catch(() => {
            swal({
              title: 'Ooops!',
              text: 'Erro ao atualizar foto de capa',
              icon: 'error',
              button: 'Ok'
            })
          })
      }
      render.readAsDataURL(file)
    },
    async created () {
      await this.getFromStorage()
    },
    async submit () {
      this.loading = true
      try {
        const id = this.user.id
        this.form.id = this.user.id
        this.form.contact_phone = this.entityData.contact_phone
        this.form.email = this.entityData.email
        this.form.name = this.entityData.name
        this.form.url = this.entityData.url

        const url = `entities/update/${id}`
        const method = id ? 'PUT' : 'POST'

        await axios.request({ method, url, data: this.form })

        swal({
          title: 'Sucesso!',
          text: `Conta ${id ? 'atualizada' : 'cadastrada'} com sucesso.`,
          icon: 'success',
          timer: 4000
        }).then(() => {
          this.form = { ...defaultForm }
        })
      } catch (error) {
        swal({
          title: 'Ooops!',
          text: 'erro',
          icon: 'error',
          button: 'Ok'
        })
      } finally {
        this.loading = false
      }
    }
  },
  computed: {
    ...mapGetters('user', ['user'])
  },
  components: {
    baseModal: () => import('@/components/modal'),
    Input: () => import('../../components/input.vue')
  }
}
</script>
<style scoped>
.modal__entity--brand {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.modal-info-entity {
  position: relative;
}
.entities-avatar {
  width: 75px;
  height: 75px;
  border-radius: 50%;
  font-weight: bold;
  background: #47be71;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 26px;
  margin-right: 1rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  position: absolute;
  top: calc(175px - 75px / 2);
  left: 2rem;
  cursor: pointer;
}

.modal__entity--text p {
  line-height: 1.75rem;
}

.modal__entity--cover {
  cursor: pointer;
}

button {
  margin-top: 1rem;
  padding: 1rem;
  font-weight: bold;
  margin-left: auto;
  margin-right: auto;
  display: block;
  width: calc(100% - 1rem);
  border-radius: 0.75rem;
}
</style>
